import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatCardModule, MatDialogModule, MatInputModule,
  MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatGridListModule,
  MatSidenavModule, MatDividerModule, MatListModule, MatSnackBarModule, MatProgressSpinnerModule,
  MatExpansionModule, MatSelectModule, MatCheckboxModule, MatDatepickerModule, MatSortModule
} from '@angular/material';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    AngularEditorModule,
    MatSortModule
  ],
  exports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    AngularEditorModule,
    MatSortModule
   ],
})
export class CustomMaterialModule {}

