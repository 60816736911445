import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { of as observableOf, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const KEY = 'hh_auth_user';
@Injectable()
export class AuthUserService {
  public authenticated = false;
  public originalUser: any = {};
  public token = '';
  public user = {
    name: '',
    email: ''
  };

  constructor(private authService: AuthService) {
    try {
      const user = JSON.parse(sessionStorage.getItem(KEY));
      this.setUser(user);
    } catch (error) {
      console.log('err=', error);
    }
  }

  public login(data: any): Observable<object> {
    return this.authService.authenticate(data)
      .pipe(
        switchMap((result: any) => {
          const user = result.response;
          user.token = result.response.token;
          return this.setUser(user);
        })
      );
  }

  private setUser(user: any) {
    sessionStorage.setItem(KEY, JSON.stringify(user));
    this.token = user.token;
    this.originalUser = user;
    this.user.name = user.userFirstname;
    this.user.email = user.userEmail;
    this.authenticated = true;
    return observableOf(user);
  }

  public getUser() {
    return this.user;
  }

  public logout() {
    sessionStorage.removeItem(KEY);
    this.authenticated = false;
    this.token = '';
    this.originalUser = {};
  }

  public getToken() {
    return this.token;
  }

  public getOriginalUser() {
    return this.originalUser;
  }

  public isAdmin() {
    return this.originalUser.accountType === 'Admin';
  }

  public isAuthenticated() {
    return this.authenticated;
  }
}
