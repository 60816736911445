import { HttpInterceptor, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUserService } from './auth-user.service';
import { environment } from 'src/environments/environment';
import { AuthApiUrls } from './auth.enum';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authUserService: AuthUserService,
              private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestedURL = req.url.replace(environment.apiUrl, '');
    let copiedReq = req;
    if (requestedURL !== AuthApiUrls.login) {
      copiedReq = req.clone({ headers: req.headers.set('x-access-token', this.authUserService.getToken()) });
    }
    return next.handle(copiedReq).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {}
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.authUserService.logout();
          this.router.navigate(['auth']);
        }
      }
    }));
  }
}
